html,
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    color: inherit;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

*:hover,
*:active,
*:focus {
    outline: 0;
}

*::before,
*::after {
    box-sizing: border-box;
    color: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
form {
    display: block;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

img {
    max-width: 100%;
    max-height: 100%;
}

html,
body {
    height: 100%;
}
