@import './assets/reset.css';

@import (less) '~@angular/cdk/overlay-prebuilt.css';
@import '~@spryker/styles/src/lib/themes/default/variables/index.less';
@import '~@spryker/styles/src/lib/themes/default/mixins/index.less';
@import '~@spryker/grid/src/lib/grid/grid.less';

body {
    font-family: @font-family;
}

.spy-grid-mixin();
